import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBadge.figmaUrl.android} codeUrl={checklists.componentBadge.codeUrl.android} checklists={checklists.componentBadge.checklists} mdxType="ChecklistResourcesSection" />
    <div {...{
      "className": "item-one",
      "align": "center"
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "padding": "7px 0"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/android-badge-variant-1.png",
          "align": "center",
          "resize-mode": "contain"
        }}></img>{`
  `}</div>
    </div>
    <div {...{
      "className": "item-one",
      "align": "center"
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "padding": "7px 0"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/android-badge-icon-1.png",
          "align": "center",
          "resize-mode": "contain"
        }}></img>{`
  `}</div>
    </div>
    <br />
    <br />
    <p>{`Badge are small status descriptors for ui elements. A badge consists of a small circle, usually containing a number or other short character, that appears near other objects. Badges should always be applied to block level elements.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`The following is the format for using Badge.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LgnBadge(
        iconLeft = {
            Icon(imageVector = Icons.Default.Warning, contentDescription = "")
        },
        iconRight = {
            Icon(imageVector = Icons.Default.Warning, contentDescription = "")
        }
    ) {
        Text(text = "123")
    }
`}</code></pre>
    <h2>{`Variants`}</h2>
    <h3>{`Size`}</h3>
    <p>{`Badges has two sizes, `}<inlineCode parentName="p">{`Medium`}</inlineCode>{` and `}<inlineCode parentName="p">{`Small`}</inlineCode>{` .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    //Badge Medium
    LgnBadge(
        size = LgnBadgeSize.Medium
    ) {
        Text(text = "123")
    }
    
    //Badge Small
    LgnBadge(
        size = LgnBadgeSize.Small
    ) {
        Text(text = "123")
    }
`}</code></pre>
    <h3>{`Appearance`}</h3>
    <p>{`There are 3 appearance that you can use for Badge.`}</p>
    <h4>{`Dot`}</h4>
    <p>{`Dot is basic usage of Badge .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LgnBadgeDot()
`}</code></pre>
    <h4>{`Label`}</h4>
    <p>{`You can give label to your Bagde by adding `}<inlineCode parentName="p">{`Text`}</inlineCode>{` parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LgnBadge() {
        Text(text = "123")
    }
`}</code></pre>
    <h4>{`Rounded`}</h4>
    <p>{`You can give icon on center of the badge rounded.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LgnBadgeRounded() {
        Icon(imageVector = Icons.Default.AccountCircle, contentDescription = "")
    }
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <h3>{`LgnBadge`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A modifier that can be used to adjust the layout or drawing content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`iconLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`@Composable (() -> Unit)?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A composable function that describes the icon on the left side of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`iconRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`@Composable (() -> Unit)?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A composable function that describes the icon on the right side of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`containerColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the badge container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contentColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the badge content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnBorderStroke`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The border of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnBadgeSize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`@Composable () -> Unit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A composable function that describes the content of the badge.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`LgnBadgeDot`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A modifier that can be used to adjust the layout or drawing content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnBorderStroke`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The border of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnBadgeSize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the badge.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`LgnBadgeRounded`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A modifier that can be used to adjust the layout or drawing content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`containerColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the badge container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contentColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the badge content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnBorderStroke`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The border of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LgnBadgeSizeRounded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`@Composable () -> Unit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A composable function that describes the content of the badge.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      